// Here you can add other styles
.select2-container {
  width: auto !important;

  .select2-search__field {
    width: auto !important;
  }
}

.text-center{
  text-align: center;
}